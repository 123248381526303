import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { INotification } from '../models/notification';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationDataSourceService extends DataSource<INotification | undefined> {
  constructor(private notificationService: NotificationService) {
    super();
    this._fetchNotificationPage();
  }

  cachedNotifications = Array.from<INotification>({ length: 0 });
  private dataStream = new BehaviorSubject<(INotification | undefined)[]>(this.cachedNotifications);
  private subscription = new Subscription();
  private pageSize = 10;
  private lastPage = 1;
  public unreadCount!: number;
  public isLoading!: boolean;
  static isOpen: boolean;

  connect(
    collectionViewer: CollectionViewer
  ): Observable<(INotification | undefined)[] | ReadonlyArray<INotification | undefined>> {
    this.subscription.add(
      collectionViewer.viewChange.subscribe(range => {
        const currentPage = this._getPageForIndex(range.end);
        if (NotificationDataSourceService.isOpen && currentPage == this.lastPage) {
          this.lastPage++;
          this._fetchNotificationPage();
        }
      })
    );
    return this.dataStream;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subscription.unsubscribe();
  }

  private _fetchNotificationPage(): void {
    this.isLoading = true;

    const message = this.notificationService.getMany({ limit: this.pageSize, page: this.lastPage });
    const messageCount = this.notificationService.countNotification();

    forkJoin([message, messageCount]).subscribe(([message, messageCount]) => {
      this.cachedNotifications = message.list;
      this.unreadCount = messageCount.notification_count;
      this.dataStream.next(this.cachedNotifications);
      this.isLoading = false;
    });
  }

  private _getPageForIndex(i: number): number {
    return Math.floor(i / this.pageSize);
  }
}
