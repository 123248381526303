import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL_VAR } from '../models/global-variable';
import { INotification } from '../models/notification';
import { BaseCrudService } from './base-crud.service';

@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseCrudService<INotification> {
  constructor(injector: Injector) {
    super(injector);
    this.path = `/${GLOBAL_VAR.PRE_API_PATH}/notification`;
  }

  countNotification(): Observable<{notification_count: number}> {
    return this.requestService.getJSON(`${this.path}/count-notification`);
  }

  seenOne(_id: string): Observable<INotification> {
    return this.requestService.postJSON(`${this.path}/${_id}/seen`, {});
  }

  markAllSeen(): Observable<unknown> {
    return this.requestService.postJSON(`${this.path}/mark-all-seen`, {});
  }
}
